import './App.css';
import React from 'react';
import Main from "./components/main/main";
const App = () => {
  return (
      <div className="App">
        <Main />
      </div>
  );
}

export default App;
